<template>
    <div>
        <!-- About Us -->
        <section class="privacy-policy style-two">
            <div class="auto-container">
                <div class="row mt-5">
                    <h2>تاريخ السريان: (مارس 2021)</h2>
                    <!-- Info Column -->
                    <div class="info-column col-12">
                        <h3><strong>1)</strong> المقدمة</h3>

                        <section>
                            <p>
                                <strong>1.1.</strong> تعد شركة نزهة للخدمات
                                العقارية (المشار إليها بـ "نحن" أو "لنا" أو
                                "لدينا") هي المشغل والمتحكِم والمسؤول عن تطبيق
                                نزهة، وهي شركة مسجلة بموجب قوانين
                                سلطنة عمان بموجب السجل التجاري رقم (1364848)
                                ومكتبها الرئيسي في ولاية بركاء – سلطنة عمان.
                            </p>

                            <p>
                                <strong>1.2.</strong> يرجى قراءة هذه السياسة
                                ("سياسة الخصوصية") بعناية تامة لأنها تحتوي على
                                معلومات هامة عن الإجراءات والممارسات المتعلقة
                                بمعلوماتك الشخصيّة عند استخدام تطبيق
                                نزهة، حيث توضِح هذه السياسة الطرق
                                والإجراءات المتبعة لدينا لجمع واستخدام ومشاركة
                                وتخزين معلوماتك الشخصيّة عند زيارة واستخدام
                                تطبيق نزهة أو إجراء حجوزات من خلال
                                التطبيق.
                            </p>

                            <p>
                                <strong>1.3.</strong> تنطبق هذه السياسة على جميع
                                المعلومات الشخصيّة التي يتم جمعها من خلال تطبيق
                                نزهة وخدماته، ولا تنطبق على أيّ
                                روابط خارجية لمواقع أو تطبيقات أخرى قد ترتبط بنا
                                حتى ولو تم الوصول إليها من خلال التطبيق، وتتناول
                                هذه السياسة على وجه التحديد التزاماتنا فيما
                                يتعلق بقوانين سلطنة عمان، ولن يتم استخدام
                                معلوماتك الشخصيّة التي تقدمها لنا إلا بالطرق
                                الموضحة في هذه السياسة.
                            </p>
                        </section>
                    </div>

                    <div class="info-column col-12">
                        <h3>
                            <strong>2)</strong> المبادئ العامة لجمع المعلومات
                            الشخصية واستخدامها والكشف عنها
                        </h3>
                        <section>
                            <h4>
                                <strong>2.1.</strong> في هذه السياسة، يكون
                                للكلمات التالية المعاني المشار بجانب كل منها:
                            </h4>

                            <p>
                                <strong>2.1.1.</strong> "التطبيق": يشير إلى
                                تطبيق نزهة.
                            </p>

                            <p>
                                <strong>2.1.2.</strong> "المستخدم": يشير إلى كل
                                من يستخدم أو يقوم بإجراء حجوزات من خلال التطبيق
                            </p>

                            <p>
                                <strong>2.1.3.</strong> "المعلومات الشخصية":
                                تشير إلى أيّ معلومات عن فرد يمكن التعرّف عليه من
                                تلك المعلومات أو من معلومات أخرى التي يمكن
                                الوصول إليها، بما في ذلك على سبيل المثال لا
                                الحصر، معلومات مثل الاسم والعنوان ورقم الهاتف
                                وعنوان البريد الإلكتروني والموقع الجغرافي.
                            </p>

                            <p>
                                <strong>2.1.4.</strong> "المعلومات الأخرى": تشير
                                إلى أي معلومات لا تكشف بشكل محدد هوية المستخدم
                                أو لا تتعلق مباشرة بفرد، مثل بيانات الاستخدام أو
                                الموقع الجغرافي.
                            </p>

                            <p>
                                <strong>2.1.5.</strong> "المعالجة": تشير إلى أي
                                عملية يتم إجراؤها بناء على معلومات شخصية، سواء
                                بطريقة تلقائية أم لا.
                            </p>

                            <p>
                                <strong>2.1.6.</strong> "مقدمو خدمات": تشير إلى
                                أيّ كيان يقوم بمعالجة البيانات نيابة عن مراقب
                                البيانات، قد نستخدم مختلف الخدمات من مقدمي
                                الخدمة من أجل معالجة بياناتك بشكل أكثر فعالية.
                            </p>
                        </section>

                        <section>
                            <h4>
                                <strong>2.2.</strong> عندما نجمع المعلومات
                                الشخصيّة؛ فإننا سنقوم وفقًا لما تسمح به القوانين
                                السارية بما يلي:
                            </h4>

                            <p>
                                <strong>2.2.1.</strong> نشر نسخة من هذه السياسة
                                لتوضيح ممارساتنا.
                            </p>

                            <p>
                                <strong>2.2.2.</strong> جمع المعلومات الشخصيّة
                                لأغراض محددة ومشروعة فقط.
                            </p>

                            <p>
                                <strong>2.2.3.</strong> معالجة معلوماتك الشخصيّة
                                بطريقة تتفق مع الأغراض التي تم جمعها من أجلها أو
                                التي وافقت عليها.
                            </p>

                            <p>
                                <strong>2.2.4.</strong> اتخاذ خطوات معقولة
                                للتأكد وضمان أن معلوماتك الشخصيّة موثوقة ودقيقة
                                وكاملة ومحدّثة.
                            </p>

                            <p>
                                <strong>2.2.5.</strong> اتخاذ التدابير المناسبة
                                لتوفير الحماية الكافية للمعلومات الشخصيّة التي
                                يتم الإفصاح عنها لأطراف ثالثة.
                            </p>
                        </section>
                    </div>

                    <div class="info-column col-12">
                        <h3><strong>3)</strong> جمع المعلومات الشخصيّة</h3>

                        <section>
                            <h4>
                                باستخدامك لخدماتنا فأنت توافق على قيامنا بجمع
                                بياناتك الشخصيّة التي ترتبط بالخدمات بما في ذلك:
                            </h4>

                            <p>
                                <strong>3.1.</strong> نجمع معلومات منك عندما
                                تسجل حساب في التطبيق، على سبيل المثال: (الاسم،
                                والبريد الإلكتروني، ورقم الهاتف، وغيرها من
                                البيانات التي تطلب منك).
                            </p>

                            <p>
                                <strong>3.2.</strong> نجمع أيضًا معلومات عند
                                استخدام الخدمات أو إجراء حجوزات بغرض تأكيدها،
                                على سبيل المثال: (بيانات العميل، ونوع العقار،
                                وتاريخ الحجز، وفترة الإيجار، وتاريخ الوصول
                                وتاريخ المغادرة، وغيرها من البيانات).
                            </p>

                            <p>
                                <strong>3.3.</strong> نجمع أيضًا المعلومات
                                التقنية التي يوفرها لنا جهازك فيما يتعلق
                                باستخدامك للخدمات، مثل عنوان بروتوكول شبكة
                                الإنترنت (IP) المستخدم لربط جهازك بشبكة
                                الانترنت، ومزود خدمة الإنترنت، وصفحات
                                الإحالة/الخروج، ومعرّفات الجهاز، ومعرّف الإعلان،
                                ونظام التشغيل، وأنواع الوظائف الإضافية، والتاريخ
                                والوقت، وعدد النقرات، ومعلومات من خلال جهازك،
                                مثل نوع جهازك، ودقة الشاشة واسم نظام التشغيل
                                والإصدار، والشركة المصنّعة للجهاز والطراز،
                                واللغة، واسم ونسخة الخدمات التي تستخدمها. نحن
                                نستخدم هذه المعلومات لضمان عمل الخدمات بشكل
                                صحيح.
                            </p>

                            <p>
                                <strong>3.4.</strong> قد نجمع أيضًا معلومات
                                شخصية منك عندما تتصل بالدعم الفني لدينا أو
                                تتواصل معنا بطريقة أخرى، مثل عنوان البريد
                                الإلكتروني أو رقم الهاتف أو العنوان البريدي.
                            </p>

                            <p>
                                <strong>3.5.</strong> نجمع أيضًا معلومات الموقع
                                الجغرافي من خلال بعض الخصائص التي تحدد موقعك
                                الفعلي، مثل إشارة GPS الخاصة بجهازك أو معلومات
                                نقاط الوصول إلى Wi-Fi القريبة وأبراج الهواتف
                                الخلوية التي قد يتم إرسالها إلينا عند استخدامك
                                لخدمات معينة وتمكين وظيفة الموقع الجغرافي. يمكنك
                                تغيير إعدادات الخصوصية في جهازك لإيقاف تشغيل
                                وظيفة مشاركة معلومات موقعك الجغرافي.
                            </p>

                            <p>
                                <strong>3.6.</strong> بالإضافة إلى المعلومات
                                التي نجمعها منك من خلال خدماتنا، قد نتلقى
                                معلومات عنك من مصادر أخرى، مثل المعلومات التي
                                يشاركها المُعلنون والمُطورين والناشرون والشركاء
                                التابعون والجهات الخارجية الأخرى أو صفحات ومنصات
                                وسائل التواصل الاجتماعي، على سبيل المثال تويتر
                                وانستغرام وغيرها.
                            </p>
                        </section>
                    </div>

                    <div class="info-column col-12">
                        <h3><strong>4)</strong> استخدام المعلومات الشخصيّة</h3>

                        <section>
                            <h4>
                                تستخدم شركة نزهة للخدمات العقارية المعلومات
                                الشخصيّة التي نجمعها منك لتزويدك بالخدمات، وبشكل
                                أكثر تحديدًا للأغراض التالية:
                            </h4>

                            <p>
                                <strong>4.1.</strong> للتعرّف عليك أو التحقق من
                                هويتك وإتمام عملية الحجز.
                            </p>

                            <p>
                                <strong>4.2.</strong> لتقديم الخدمات وتمكينك من
                                الوصول والاستفادة من مزايا وخدمات التطبيق.
                            </p>

                            <p>
                                <strong>4.3.</strong> لتوفير الدعم الفني والرد
                                على استفساراتك والاتصالات الإلكترونية.
                            </p>

                            <p>
                                <strong>4.4.</strong> لقياس مدى رضا عملائنا
                                وتقديم خدمة العملاء.
                            </p>

                            <p>
                                <strong>4.5.</strong> لإرسال معلومات إدارية أو
                                مهمّة، أو إشعارات تتعلق بتطوير الخدمات أو أيّ
                                تغييرات في الشروط والسياسات الخاصة بالتطبيق.
                            </p>

                            <p>
                                <strong>4.6.</strong> لتزويد المستخدم بمواد
                                تسويقية وترويجية عن الخدمات وفقًا لاهتماماته.
                            </p>

                            <p>
                                <strong>4.7.</strong> للأغراض الإحصائية
                                والتحليلية والتسويقية والترويجية والدراسات
                                الاستقصائية.
                            </p>

                            <p>
                                <strong>4.8.</strong> لأغراض مراقبة الاحتيال
                                والأمن، على سبيل المثال، اكتشاف ومنع الهجمات
                                الإلكترونية أو محاولات سرقة معلومات الهوية.
                            </p>

                            <p>
                                <strong>4.9.</strong> فيما يتعلق بالإجراءات أو
                                المتطلبات القانونية للتحقيق في أيّ مطالبات أو
                                نزاعات تتعلق باستخدام الخدمات، أو للرد على
                                الطلبات والاتصالات والاستفسارات الرسمية من
                                السلطات المختصة، والجهات الحكومية.
                            </p>
                        </section>
                    </div>

                    <div class="info-column col-12">
                        <h3><strong>5)</strong> مشاركة المعلومات الشخصيّة</h3>

                        <section>
                            <h4>
                                <strong>5.1.</strong> يحق لنا وفقًا للأغراض
                                المذكورة أعلاه مشاركة معلوماتك الشخصيّة مع
                                الموظفين وملاك العقارات وأطراف ثالثة تشترك معنا
                                في تقديم الخدمات والأشخاص المتعاقدين معنا (مثل
                                مقدمو خدمات تكنولوجيا المعلومات، ومقدمو خدمات
                                الدفع، والتحليلات والأدوات التسويقية والإعلانية)
                                وذلك لأغراض المساعدة في تقديم خدماتنا وتشغيلها
                                وتحسينها وتطويرها ومعالجة العمليات التي تقوم بها
                                من خلال التطبيق أو تنفيذ الخدمات.
                            </h4>
                        </section>

                        <section>
                            <h4>
                                <strong>5.2.</strong> سنقوم أيضًا بمشاركة
                                معلوماتك الشخصيّة مع أيّ كيان نلتزم تجاهه
                                بالإفصاح عن معلوماتك أو مشاركتها متى كان ذلك
                                ضروريًا لـ:
                            </h4>
                            <p>
                                <strong>5.2.1</strong> الامتثال لقانون أو لائحة
                                أو أمر محكمة أو أيّ التزام أو إجراء قانوني آخر.
                            </p>
                            <p>
                                <strong>5.2.2</strong> إنفاذ أو تطبيق شروطنا
                                والمتطلبات القانونية الأخرى.
                            </p>
                            <p>
                                <strong>5.2.3</strong> أغراض ومنع الاحتيال
                                وانتهاك حقوق الملكية الفكرية أو انتهاك عقودنا
                                واتفاقياتنا أو انتهاك القانون أو إساءة استخدام
                                التطبيق والخدمات.
                            </p>
                            <p>
                                <strong>5.2.4</strong> حماية حقوقنا أو ممتلكاتنا
                                أو حقوق المستخدمين.
                            </p>
                            <p>
                                <strong>5.2.5</strong> في أيّ ظروف مماثلة، وفي
                                حالة حدوث ذلك، سنتخذ الخطوات المناسبة لحماية
                                معلوماتك الشخصيّة.
                            </p>
                        </section>

                        <section>
                            <h4>
                                <strong>5.3.</strong> في جميع الأحوال عندما
                                نشارك معلوماتك الشخصيّة سنطلب من هذه الجهات
                                الخارجية استخدام المعلومات التي نشاركها معهم بما
                                يتوافق مع سياسة الخصوصية هذه وقانون حماية
                                البيانات المعمول به في سلطنة عمان.
                            </h4>
                        </section>
                    </div>

                    <div class="info-column col-12">
                        <h3><strong>6)</strong> تخزين المعلومات الشخصيّة</h3>

                        <section>
                            <h4>
                                <strong>6.1.</strong> أنت تفوضنا صراحة بإنشاء
                                سجل إلكتروني يحتوي عل كل أو بعض مِن معلوماتك
                                وبياناتك التي قمت بتزويدنا بها وفقًا لهذه
                                السياسة، وسيتم الاحتفاظ بهذه البيانات في
                                أرشيفاتنا للفترة اللازمة لتحقيق الأغراض المنصوص
                                عليها في هذه السياسة ما لم نكن بحاجة إلى فترة
                                أطول للاحتفاظ بها أو وفقاً لما تسمح به القوانين،
                                وستختلف المدة التي نحتفظ فيها بمعلوماتك الشخصية
                                اعتمادًا على الغرض من المعلومات التي تم جمعها
                                واستخدامها.
                            </h4>
                        </section>

                        <section>
                            <h4>
                                <strong>6.2.</strong> تشمل المعايير المستخدمة
                                لتحديد فترات الاحتفاظ لدينا ما يلي:
                            </h4>
                            <p>
                                <strong>6.2.1</strong> طول الفترة الزمنية التي
                                نتمتع فيها بعلاقة مستمرة معك وتوفير الخدمات لك
                                (على سبيل المثال، طالما لديك حساب معنا أو فترة
                                استخدامك للخدمات).
                            </p>
                            <p>
                                <strong>6.2.2</strong> إذا كان هناك التزام
                                قانوني نخضع له (على سبيل المثال، تتطلب بعض
                                القوانين منا الاحتفاظ بسجلات معاملاتك لفترة
                                زمنية معينة قبل أن نحذفها).
                            </p>
                            <p>
                                <strong>6.2.3</strong> إذا كان الاحتفاظ ضرورياً
                                لوضعنا القانونية (على سبيل المثال، قوانين
                                التقادم أو التقاضي أو التحقيقات التنظيمية).
                            </p>
                        </section>
                    </div>

                    <div class="info-column col-12">
                        <h3><strong>7)</strong> حماية المعلومات الشخصيّة</h3>
                        <p>
                            يعد أمن وسلامة بياناتك الشخصيّة أمراً هاماً بالنسبة
                            لنا ومن أولوياتنا، لذا نسعى لتأمين وحماية معلوماتك
                            وبياناتك الشخصيّة وعدم الاطلاع عليها من الغير بما
                            يخالف السياسة المعمول بها لدينا، ونتخذ في سبيل ذلك
                            كافة التدابير الأمنية اللازمة والمناسبة لحماية
                            بياناتك الشخصيّة من الفقد أو التغيير أو الإفصاح أو
                            الوصول غير المصرح به أو الاستخدام غير المقصود وغير
                            القانوني، كما أننا نعمل على حماية هذه المعلومات عن
                            طريق "سيرفرات معيّنة" محمية بموجب أنظمة الحماية
                            الإلكترونية، ومع ذلك من الممكن ألا يكون هذا المستوى
                            من الحماية فعّال بنسبة 100% إلا إذا كنت تتبع سياسات
                            أمنية خاصة بك.
                        </p>
                    </div>

                    <div class="info-column col-12">
                        <h3><strong>8)</strong> بيع المعلومات الشخصيّة</h3>
                        <p>
                            نحن لا نبيع أو نؤجر أو نتاجر بالمعلومات الشخصيّة
                            للمستخدمين إلى أيّ جهات خارجية بأيّ شكل يمكن من
                            خلاله التعرّف عليك شخصيًا، ولا نسمح للغير باستخدامها
                            لأغراض التسويق المباشر أو غير المباشر دون الحصول على
                            موافقتك، ولكن يتم استخدام معلوماتك للأغراض المعلن
                            عنها في هذه السياسة.
                        </p>
                    </div>

                    <div class="info-column col-12">
                        <h3><strong>9)</strong> الإفصاح القانوني</h3>
                        <p>
                            في حالة حدوث أيّ انتقال أو تغيير لكل أو جزء من
                            أعمالنا أو أصولنا، يجوز لنا مشاركة المعلومات
                            الشخصيّة مع الطرف الثالث أثناء المفاوضات فيما يتعلق
                            بأيّ عملية اندماج أو استحواذ أو إعادة تنظيم أو بيع
                            الأصول أو مشروع مشترك أو التنازل أو التحويل أو أيّ
                            تصرف مشابه، وستكون البيانات الشخصيّة جزء من هذه
                            العملية.
                        </p>
                    </div>

                    <div class="info-column col-12">
                        <h3><strong>10)</strong> المعلومات الشخصيّة للأطفال</h3>
                        <p>
                            لا نجمع أي معلومات عن قصد من الأطفال ولا نعتزم
                            القيام بذلك في المستقبل، وإذا كنت أحد الوالدين أو
                            الوصي واكتشفت أن طفلك القاصر قد قدم لنا معلومات
                            شخصيّة دون إذنك أو موافقتك المسبقة، يرُجى الاتصال
                            بنا للتأكد من إزالة المعلومات وإلغاء اشتراكه في أي
                            مراسلات تسويقية.
                        </p>
                    </div>

                    <div class="info-column col-12">
                        <h3><strong>11)</strong> بيانات الدفع</h3>
                        <section>
                            <p>
                                <strong>11.1.</strong> لن نقوم بتخزين بيانات
                                وتفاصيل بطاقتك المستخدمة في الدفع، ولكن يتم
                                تقديم هذه المعلومات مباشرةً إلى معالجات الدفع
                                التابعة لجهات خارجية، والتي يخضع استخدامهم
                                لمعلوماتك الشخصيّة لسياسة الخصوصية الخاصة بهم.
                                تلتزم معالجات الدفع هذه بالمعايير التي وضعتها
                                PCI-DSS، وهو جهد مشترك لعلامات تجارية مثل فيزا
                                وماستركارد. تساعد متطلبات PCI-DSS في ضمان
                                المعالجة الآمنة لمعلومات الدفع.
                            </p>

                            <p>
                                <strong>11.2.</strong> عند نقل معلومات بطاقتك
                                الائتمانية أو الخصم من خلال خدماتنا، فستتم
                                حمايتها بواسطة بروتوكولات التشفير؛ نحن نستخدم
                                معالجات دفع تابعة لجهات خارجية تتحكم في معلومات
                                بطاقتك الائتمانية، وتتطلب عقودنا مع الأطراف
                                الثالثة التي تتلقى معلومات بطاقتك الائتمانية أو
                                الخصم الحفاظ عليها آمنة وسرية.
                            </p>

                            <p>
                                <strong>11.3.</strong> نحن لا نتحمل المسؤولية عن
                                أيّ ممارسات أو سياسات أو إجراءات خاصة بمعالجي
                                الدفع.
                            </p>
                        </section>
                    </div>

                    <div class="info-column col-12">
                        <h3><strong>12)</strong> الاتصالات الإلكترونية</h3>
                        <p>
                            قد نتواصل معك عبر البريد الإلكتروني، على سبيل
                            المثال، قد نرسل إليك معلومات تتعلق بمنتجاتنا
                            وخدماتنا، بما في ذلك التواصل معك حول العروض والرسائل
                            الترويجية والأحداث القادمة والإشعارات الأخرى حول
                            المنتجات والخدمات، والإشعارات التقنية وتحديثات
                            الشروط والسياسات وتنبيهات الأمان ورسائل الدعم
                            والإدارة؛ إذا كنت لا ترغب في استلام مثل هذه
                            الاتصالات والرسائل منا، يمكنك إلغاء الاشتراك في هذه
                            الرسائل في أي وقت من خلال النقر على رابط إلغاء
                            الاشتراك الذي يظهر أسفل كل رسالة، أو عن طريق الاتصال
                            بنا.
                        </p>
                    </div>

                    <div class="info-column col-12">
                        <h3><strong>13)</strong> مواقع الجهات الخارجية</h3>
                        <section>
                            <p>
                                <strong>13.1.</strong> قد يتضمن التطبيق والخدمات
                                إعلانات أو روابط لمواقع خارجية لا تملكها شركة
                                نزهة للخدمات العقارية ولا تخضع لسيطرتها أو
                                إدارتها، لذلك لا نتحمل المسؤولية عن ممارسات
                                وسياسات الخصوصية أو الأمان أو خدمات مواقع هذه
                                الجهات الخارجية، ونوصيك بمراجعة سياسة الخصوصية
                                المنشورة على هذه المواقع للتعرّف على إجراءاتها
                                وسياساتها قبل تزويدها ببياناتك الشخصيّة، وتخضع
                                أيّ بيانات شخصيّة تقدمها على هذه المواقع لسياسات
                                المشغلين لهذه المواقع حتى لو تم الوصول إليها من
                                خلال التطبيق.
                            </p>

                            <p>
                                <strong>13.2.</strong> بالإضافة إلى ذلك، لا
                                نتحمل المسؤولية أيضًا عن سياسات أو ممارسات
                                الخصوصية والأمان الخاصة بالمنصات الأخرى، مثل
                                فيسبوك أو تويتر أو أيّ موفر لمنصات التواصل
                                الاجتماعي أو مزود نظام التشغيل أو مزود الخدمة
                                اللاسلكية أو الشركة المصنعة للجهاز، ونوصيك
                                بمراجعة سياسات الخصوصية الخاصة بهذه المؤسسات
                                والمواقع بعناية من أجل فهم حقوقك والتزاماتك بشكل
                                أفضل.
                            </p>
                        </section>
                    </div>

                    <div class="info-column col-12">
                        <h3><strong>14)</strong> حقوقك القانونية</h3>

                        <section>
                            <h4>
                                <strong>14.1.</strong> يحق لك طلب بعض الإجراءات
                                المتعلقة ببياناتك الشخصيّة ما لم ينص القانون
                                المحلي على خلاف ذلك:
                            </h4>

                            <p>
                                <strong>14.1.1</strong> يحق لك أن تطلب معرفة
                                البيانات التي تم جمعها في أي وقت وأن تطلب
                                تحديثها أو تصحيحها إذا كانت غير دقيقة أو غير
                                كاملة.
                            </p>

                            <p>
                                <strong>14.1.2</strong> يحق لك أن تطلب تعديل أو
                                حذف البيانات التي تم جمعها عنه طبقًا لهذه
                                السياسة أو حذف حسابك؛ ولكن قد يؤدي هذا الطلب إلى
                                توقف تقديم الخدمات له.
                            </p>

                            <p>
                                <strong>14.1.3</strong> يحق لك أن تطلب تقييد
                                معالجة معلوماتك الشخصية.
                            </p>

                            <p>
                                <strong>14.1.4</strong> يحق لك الاعتراض على
                                معالجتنا لمعلوماتك الشخصية.
                            </p>

                            <p>
                                <strong>14.1.5</strong> يحق لك تقديم شكوى إلى
                                هيئة حماية البيانات في حال استخدمت معلوماتك في
                                أغراض مخالفة لهذه السياسة أو القوانين السارية في
                                السلطنة.
                            </p>
                        </section>

                        <section>
                            <h4>
                                <strong>14.2.</strong> يرجى ملاحظة أننا قد نطلب
                                منك التحقق من هويتك قبل الرد على مثل هذه
                                الطلبات. يرجى ملاحظة أننا قد لا نتمكن من تقديم
                                الخدمة دون بعض البيانات الضرورية.
                            </h4>
                        </section>
                    </div>

                    <div class="info-column col-12">
                        <h3><strong>15)</strong> مسؤولية تقديم البيانات</h3>
                        <section>
                            <p>
                                <strong>15.1.</strong> أنت تقر بأنك تقدم بياناتك
                                لنا على مسئوليتك الشخصيّة، وفي حالة قيامك
                                بتزويدنا بأيّ بيانات خاصة بالغير بشكل كاذب أو
                                احتيالي فأنت تتحمّل المسئولية القانونية في
                                مواجهتنا وأمام الجهات المختصة وفي مواجهة المتضرر
                                من تزويدنا بتلك البيانات مع التزامك بدفع كافة
                                التعويضات المناسبة.
                            </p>

                            <p>
                                <strong>15.2.</strong> أنت تعلم وتوافق على أن
                                تطبيق نزهة يتحكم في البيانات التي
                                يسيطر عليها، وبالتالي لا يملك السيطرة على أيّ
                                بيانات قدمتها أو تقدمها لأيّ طرف آخر، فعلى سبيل
                                المثال البيانات التي تقدمها لملاك العقارات.
                            </p>

                            <p>
                                <strong>15.3.</strong> أنت توافق على إخلاء
                                مسئوليتنا في حالة قيام ملاك العقارات بإفشاء
                                بياناتك الشخصيّة.
                            </p>
                        </section>
                    </div>

                    <div class="info-column col-12">
                        <h3><strong>16)</strong> تحديثات سياسة الخصوصية</h3>
                        <p>
                            نحتفظ من وقت لآخر حسب رؤيتنا بتعديل أو تغيير جزء أو
                            كل هذه السياسة، وفي حال قمنا بإجراء أي تغييرات عليها
                            فسنقوم أيضًا بتحديث "تاريخ السريان" المبين أعلى هذه
                            الوثيقة، وستصبح أيّ تغييرات على السياسة سارية عند
                            نشر النسخة المعدّلة، وإذا كنت لا توافق على التعديلات
                            الجديدة، يجب عليك التوقف عن استخدام خدماتنا، ويعد
                            استمرارك في استخدام التطبيق بعد تحديث هذه السياسة
                            موافقة صريحة منك على هذه التعديلات وقبولاً قانونيًا
                            منك بالممارسات والأحكام الجديدة، لذا نوصيك بمراجعة
                            هذه السياسة بشكل منتظم للاطلاع على أحكامها
                            وتحديثاتها والحصول على أحدث المعلومات حول إجراءات
                            وممارسات الخصوصية المتبعة لدينا.
                        </p>
                    </div>

                    <div class="info-column col-12">
                        <h3><strong>17)</strong> الموافقة على السياسة</h3>
                        <p>
                            أنت تٌقر بأنك قرأت هذه السياسة ووافقت على جميع
                            بنودها، ويعتبر استمرارك في استخدام التطبيق أو
                            الخدمات موافقة صريحة على الالتزام بهذه السياسة، إذا
                            كان لديك أي أسئلة أو تعليقات حول هذه السياسة أو
                            ممارساتنا يرجى التواصل معنا عبر الوسائل المتاحة
                            بالتطبيق.
                        </p>
                    </div>
                </div>
            </div>
        </section>
        <!-- About Us -->
    </div>
</template>

<script>
export default {
    name: "privacyPolicy",
};
</script>

<style scoped>
.privacy-policy p, .privacy-policy h4, .privacy-policy h3 {
    margin: 30px 0;
}
</style>
